.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.name {
  color: white;
  font-family: "MyWebFont";
  text-align: "center";
  font-size: 14pt;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 30px;
    height: 30px;
  }
  .name {
    font-size: 10pt;
  }
}
