.link,
.link_active {
  color: white !important;
  font-size: 16pt !important;
  /* font-weight: bold !important; */
  font-family: MyWebFont;
  text-transform: uppercase !important;
  padding: 10px 20px !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.link_active {
  color: orange !important;
}

@media screen and (max-width: 768px) {
  .link,
  .link_active {
    font-size: 8pt !important;
    padding: 5px 5px !important;
  }
}
