.title {
  font-size: 50pt;
  font-family: MyWebFont;
  letter-spacing: 5px;
  font-weight: bold;
  color: white;
  border-bottom: 2px solid orange;
  text-transform: uppercase;
  margin-bottom: 5rem !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 20pt;
    margin-bottom: 3rem !important;
    width: min-content;
  }
}
