.body {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 65vw;
  /* height: 50vh; */
  /* padding-top: 10%;
  padding-bottom: 10%; */
  margin-top: 10%;
  margin-bottom: 10%;
  /* border: 1px solid red; */
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.content {
  text-align: left;
  margin-top: 10px;
  /* width: 85%; */
  font-family: MyWebFont;
}

.textDiv {
  width: 50%;
}

.imageDiv {
  width: 44%;
}

@media screen and (max-width: 768px) {
  .body {
    flex-direction: column;
    width: 85vw;
  }
  .textDiv {
    width: 100%;
    order: 1;
  }

  .imageDiv {
    width: 100%;
    order: 2;
    margin-top: 40px;
  }
}
