@font-face {
  font-family: "MyWebFont";
  src: url("./fonts/JetBrainsMono-Bold.woff2") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/JetBrainsMono-Bold.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

body {
  margin: 0;
  font-family: MyWebFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}