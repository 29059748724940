.title {
  font-size: 70pt;
  margin-bottom: 1rem;
  font-family: MyWebFont;
  letter-spacing: 5px;
  color: white;
  text-shadow: 2px 2px 0px orange;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.subtitle {
  font-size: 16pt;
  margin-bottom: 1rem;
  font-family: MyWebFont;
  letter-spacing: 5px;
  color: white;
  margin-top: 40px;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  font-size: 16pt;
  margin-bottom: 1rem;
  font-family: MyWebFont;
  letter-spacing: 5px;
  color: white;
  max-width: 200px;
  margin-top: 40px;
  border: 3px groove white;
  border-radius: 10px;
  background-color: transparent;
  padding: 10px 15px;
  cursor: pointer;
}

.button:hover {
  background-color: white;
  color: orange;
  transition: all 0.3s linear;
}

.icon {
  margin-right: 10px;
}

.row {
  width: 100%;
  margin-top: 40px;
  border: 1px solid red;
}

.tooltip .tooltip-inner {
  background-color: orange !important;
  font-family: MyWebFont;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 40pt;
  }
  .subtitle {
    font-size: 12pt;
  }
}


