.text {
  font-size: 14pt;
  font-family: MyWebFont;
  color: white;
  text-transform: uppercase;
  margin-bottom: 2rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .text  {
    font-size: 12pt;
  }
}
