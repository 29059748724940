.header,
.body {
  width: 50vw;
  padding: 15px;
  font-family: MyWebFont;
  margin-bottom: 15px;
  border-radius: 10px;
}
.header {
  display: flex;
  background-color: orange;
  color: white !important;
  justify-content: space-between;
  /* font-weight: bold !important; */
  /* font-size: 16pt; */
}
.body {
  background-color: white;
  color: black;
  font-size: 12pt;
}
.title,
.period {
  font-family: MyWebFont;
  font-size: 12pt !important;
  color: #000000;
  /* font-weight: bold; */
}
.title {
  width: 75%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.period {
  width: 20%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggleButtonDiv {
  width: 5%;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
}
.toggleButton {
  font-size: 14pt;
  font-weight: normal !important;
}
.toggleButton:hover {
  cursor: pointer;
}
.website:hover {
  color: orange;
  cursor: pointer;
}
.icon {
  margin-right: 7px;
  color: orange;
}
.textDiv {
  text-align: left;
  margin-top: 10px;
  width: 85%;
}
.imageDiv {
  text-align: right;
  margin-top: 10px;
  width: 15%;
}
.content {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .header,
  .body {
    width: 90vw;
  }
  .title {
    width: 70%;
  }
  .title,
  .period {
    font-size: 10pt !important;
  }
  .imageDiv {
    order: 1;
  }
  .textDiv {
    order: 2;
  }
  .content {
    flex-direction: column;
  }
}
