.subtitle {
  font-size: 20pt;
  font-family: MyWebFont;
  color: white;
  text-transform: uppercase;
  margin-bottom: 2rem;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
